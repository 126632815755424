import React from 'react';
import SocialIcons from '../SocialIcons/SocialIcons';
import './Footer.css';

const Footer = (props) => {
  const date = new Date();

  return (
    <footer className='main-container__footer'>
      {props.width >= 700 ? <></> : <SocialIcons />}
      <p>© {date.getFullYear()}</p>
    </footer>
  );
};

export default Footer;
