import { useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../api';

const useFetch = () => {
  const [streamLink, setStreamLink] = useState(null);

  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [error, setError] = useState(null);

  const getData = async () => {
    if (streamLink === null) {
      try {
        const streamData = await axios.get(API);
        setStreamLink(streamData.data.station.listen_url);
        setData(streamData.data);
        setIsloading(false);
      } catch (err) {
        setError(err);
      }
    } else {
      try {
        const streamData = await axios.get(API);
        setData(streamData.data);
        setIsloading(false);
      } catch (err) {
        setError(err);
      }
    }
  };

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return [isLoading, data, error, streamLink];
};

export default useFetch;
