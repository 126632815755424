import React from 'react';
import { IoLogoGooglePlaystore } from 'react-icons/io5';

import './SocialIcons.css';

import facebookIcon from '../../assets/icons/facebook.png';
import instagramIcon from '../../assets/icons/instagram.png';
import mailIcon from '../../assets/icons/mail.png';

const SocialIcons = () => {
  return (
    <div className='main-container__social-icons'>
      <a
        className='main-container__social-icons-icon'
        href='mailto:info@beveikradijas.com'
        target='_blank'
        rel='noreferrer'
      >
        <img src={mailIcon} alt='email' />
      </a>
      <a
        className='main-container__social-icons-icon'
        href='https://www.facebook.com/beveikradijas/'
        target='_blank'
        rel='noreferrer'
      >
        <img src={facebookIcon} alt='facebook' />
      </a>
      <a
        className='main-container__social-icons-icon'
        href='https://www.instagram.com/beveikradio/'
        target='_blank'
        rel='noreferrer'
      >
        <img src={instagramIcon} alt='instagram' />
      </a>
      <a
        className='main-container__social-icons-icon'
        href='https://play.google.com/store/apps/details?id=com.radijas.beveik'
        target='_blank'
        rel='noreferrer'
      >
        <IoLogoGooglePlaystore color='#fff' size={30} />
      </a>
    </div>
  );
};

export default SocialIcons;
