import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className='main-container__header'>
      <h1 className='main-container__header-title'>BEVEIK RADIJAS</h1>
      <h2 className='main-container__header-subtitle'>Mąstyk alternatyviai</h2>
    </header>
  );
};

export default Header;
