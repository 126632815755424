import React, { useRef, useState, useEffect } from 'react';
import NowPlaying from '../NowPlaying/NowPlaying';

const AudioPlayer = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayer = useRef();

  useEffect(() => {
    if (isPlaying) {
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      const canvas = document.getElementById('canvas');
      canvas.width = window.innerWidth;
      props.width >= 1681
        ? (canvas.height = 300)
        : props.width >= 1439
        ? (canvas.height = 250)
        : props.width <= 394
        ? (canvas.height = 200)
        : (canvas.height = 250);
      let audioSource = null;
      let analyser = null;
      audioSource = audioCtx.createMediaElementSource(audioPlayer.current);
      analyser = audioCtx.createAnalyser();
      audioSource.connect(analyser);
      analyser.connect(audioCtx.destination);
      props.width >= 476 ? (analyser.fftSize = 128) : (analyser.fftSize = 32);
      const ctx = canvas.getContext('2d');
      const bufferLength = analyser.frequencyBinCount; // the number of data values that dictate the number of bars in the canvas. Always exactly one half of the fft size
      const dataArray = new Uint8Array(bufferLength); // coverting to unsigned 8-bit integer array format because that's the format we need

      const barWidth = canvas.width / 2 / bufferLength; // the width of each bar in the canvas

      let x = 0; // used to draw the bars one after another. This will get increased by the width of one bar

      function animate() {
        x = 0;
        ctx.clearRect(0, 0, canvas.width, canvas.height); // clears the canvas
        analyser.getByteFrequencyData(dataArray); // copies the frequency data into the dataArray in place. Each item contains a number between 0 and 255
        drawVisualizer({ bufferLength, dataArray, barWidth });
        requestAnimationFrame(animate); // calls the animate function again. This method is built in
      }

      const drawVisualizer = ({ bufferLength, dataArray, barWidth }) => {
        let barHeight;
        for (let i = 0; i < bufferLength; i++) {
          props.width >= 1439
            ? (barHeight = dataArray[i] / 2)
            : (barHeight = dataArray[i] / 3); // the height of the bar is the dataArray value. Larger sounds will have a higher value and produce a taller bar
          ctx.fillStyle = '#707070';
          ctx.fillRect(
            canvas.width / 2 - x, // this will start the bars at the center of the canvas and move from right to left
            canvas.height - barHeight,
            barWidth - 5,
            barHeight
          );

          // draws the bar. the reason we're calculating Y weird here is because the canvas starts at the top left corner. So we need to start at the bottom left corner and draw the bars from there
          x += barWidth; // increases the x value by the width of the bar
        }

        for (let i = 0; i < bufferLength; i++) {
          props.width >= 1439
            ? (barHeight = dataArray[i] / 2)
            : (barHeight = dataArray[i] / 3); // the height of the bar is the dataArray value. Larger sounds will have a higher value and produce a taller bar
          ctx.fillStyle = '#707070';
          ctx.fillRect(x, canvas.height - barHeight, barWidth - 5, barHeight); // this will continue moving from left to right
          x += barWidth; // increases the x value by the width of the bar
        }
      };

      animate();
      audioPlayer.current.play();
    }
  }, [isPlaying]);

  return (
    <>
      {isPlaying ? (
        <audio ref={audioPlayer} crossOrigin='anonymous'>
          <source src={props.streamLink} type='audio/mp3' />
        </audio>
      ) : (
        <></>
      )}
      <NowPlaying
        data={props.data}
        isPlaying={isPlaying}
        playStopBtn={setIsPlaying}
        width={props.width}
      />
    </>
  );
};

export default AudioPlayer;
