import React, { useState, useEffect } from 'react';
import useFetch from './hooks/useFetch';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import TagManager from 'react-gtm-module';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {
  ReactGA.initialize('UA-124717920-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactGA4.initialize('G-2THM9YM9RD');
  ReactGA4.send('pageview');
  const tagManagerArgs = {
    gtmId: 'GTM-M7MT8PT',
  };
  TagManager.initialize(tagManagerArgs);

  const [isLoading, data, error, streamLink] = useFetch();
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <div className='main-container'>
      <Header />
      {isLoading && error === null ? (
        <div className='main-container__loading-text'>Kraunasi...</div>
      ) : (
        <AudioPlayer streamLink={streamLink} data={data} width={width} />
      )}
      <Footer width={width} />
    </div>
  );
}

export default App;
