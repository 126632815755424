import React from 'react';
import { IoStopCircleOutline, IoPlayCircleOutline } from 'react-icons/io5';
import SocialIcons from '../SocialIcons/SocialIcons';

import './NowPlaying.css';

const NowPlaying = (props) => {
  return (
    <section className='main-containe__player'>
      <div className='main-containe__player-image-container'>
        <img
          className='main-containe__player-image-container__artwork'
          src={props.data.now_playing.song.art}
          alt={props.data.now_playing.song.text}
        />
        <canvas id='canvas'></canvas>
        <button
          className='main-containe__player-image-container__btn'
          onClick={() => props.playStopBtn(!props.isPlaying)}
          type='button'
        >
          {props.isPlaying ? (
            <IoStopCircleOutline size={80} color={'#fff'} />
          ) : (
            <IoPlayCircleOutline size={80} color={'#fff'} />
          )}
        </button>
      </div>
      <div className='main-containe__player-now-playing-container'>
        <h3 className='main-containe__player-now-playing-container__text'>
          Eteryje:
        </h3>
        <h4 className='main-containe__player-now-playing-container__artist'>
          {props.data.now_playing.song.artist}
        </h4>
        <h5 className='main-containe__player-now-playing-container__title'>
          {props.data.now_playing.song.title}
        </h5>
      </div>
      <div className='main-containe__player-next-playing-container'>
        <div className='main-containe__player-next-playing-container__text-container'>
          <h3 className='main-containe__player-next-playing-container__text-container__text'>
            Netrukus eteryje:
          </h3>
          <h4 className='main-containe__player-next-playing-container__text-container__song'>
            {props.data.playing_next.song.text}
          </h4>
        </div>
        {props.width >= 700 ? <SocialIcons /> : <></>}
      </div>
    </section>
  );
};

export default NowPlaying;
